import axios from './axios';
import api from './api';
import { isArray } from '../helpers/javascript';
import { API_HOST } from './app.config';
import { getAuthHeader } from './security.service';

export const find = () =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/notifications`,
      method: 'GET'
    })
      .then(response => {
        if (isArray(response.data)) resolve(response.data);
        else reject();
      })
      .catch(() => {
        reject();
      });
  });

export const remove = ({ _id }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/notifications/${_id}`,
      method: 'DELETE'
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(() => {
        reject();
      });
  });

export const isFirstTimeLoad = key => {
  const value = localStorage.getItem(key || 'woxo_tutorial_load');
  if (value) return false;
  return true;
};

export const setFirstTimeLoad = key => {
  localStorage.setItem(key || 'woxo_tutorial_load', 'true');
};
