import axios from './axios';
import api from './api';
import { API_HOST } from './app.config';
import { isArray, isObject } from '../helpers/javascript';
import translate from '../data/translations';
import { isBrowser } from '../helpers/browser';
import { useAuthHeaders } from './security.service';
import createStore from 'zustand';
import { persist } from 'zustand/middleware';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

export const useTeamStore = createStore(
  persist(
    set => ({
      team: null,
      setTeam: team => {
        set({ team });
      }
    }),
    { name: 'woxo-team', version: 1 }
  )
);

const get3rdPartyUserId = () => {
  const hjUserId =
    (window.getCookie &&
      typeof window.getCookie === 'function' &&
      window.getCookie('__hjUserId')) ||
    '';
  const gaUserId =
    (window.getCookie &&
      typeof window.getCookie === 'function' &&
      window.getCookie('_ga') &&
      `${window.getCookie('_ga').split('.')[2]}.${window.getCookie('_ga').split('.')[3]}`) ||
    '';
  const audience = isBrowser() ? localStorage.getItem('user-audience') || 'widget' : 'widget';
  return { hjUserId, gaUserId, audience };
};
export const create = ({ jwt, userTags }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/teams/user`,
      method: 'POST',
      headers: { Authorization: `Bearer ${jwt}` },
      data: { ...get3rdPartyUserId(), userTags }
    })
      .then(response => {
        if (isObject(response.data)) {
          useTeamStore.getState().setTeam(response.data);
          resolve(response.data);
        } else reject(new Error(translate('providerSignUpError', 'en')));
      })
      .catch(() => {
        reject(new Error(translate('invalidLoginError', 'en')));
      });
  });

export function useInviteToTeam() {
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/invite`;
  const method = 'POST';

  return useSWRMutation([url, method], async ([url, method], { arg: { emails } }) => {
    return axios({
      url,
      method,
      headers: await getAuthHeaders(),
      data: { emails }
    })
      .then(response => {
        if (response.data.success && isArray(response.data.success)) {
          return response.data.success;
        } else {
          throw new Error(translate('error.network', 'en'));
        }
      })
      .catch(() => {
        throw new Error(translate('error.network', 'en'));
      });
  });
}

export const acceptInvitation = ({ code }) =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/teams/invite/accept`,
      method: 'POST',
      data: { code }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject(new Error(translate(response.data.error, 'en')));
      })
      .catch(() => {
        reject(new Error(translate('error.network', 'en')));
      });
  });

export function useTeamMembers() {
  const { data: defaultTeam, isLoading: isLoadingDefaultTeam } = useDefaultTeam();
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/members`;
  const method = 'GET';
  return useSWR(
    defaultTeam ? [url, method] : null,
    async () => {
      return axios({
        url: `${API_HOST}/custom/teams/members`,
        method,
        headers: { ...(await getAuthHeaders()), 'X-Mission-Control-Team': defaultTeam._id }
      })
        .then(response => {
          if (response.data.success) {
            return response.data.success;
          } else {
            throw new Error(translate(response.data.error, 'en'));
          }
        })
        .catch(() => {
          throw new Error(translate('error.network', 'en'));
        });
    },
    { fallbackData: [] }
  );
}

export function useRemoveFromTeam() {
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/members`;
  const method = 'DELETE';
  return useSWRMutation([url, method], async ([url, method], { arg: userId }) => {
    return axios({
      url: `${url}/${userId}`,
      method,
      headers: await getAuthHeaders()
    })
      .then(response => {
        if (response.data.success) {
          return response.data.success;
        } else {
          throw new Error(translate(response.data.error, 'en'));
        }
      })
      .catch(() => {
        throw new Error(translate('error.network', 'en'));
      });
  });
}

export function useTeams() {
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/user`;
  const method = 'GET';

  return useSWR(method + '-' + url, async () => {
    return axios({
      url,
      method,
      headers: await getAuthHeaders()
    })
      .then(response => {
        if (response.data.success) {
          return response.data.success;
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch(() => {
        throw new Error(translate('error.network', 'en'));
      });
  });
}

export function useDefaultTeam() {
  const { getAuthHeaders } = useAuthHeaders();
  const url = `${API_HOST}/custom/teams/default`;
  const method = 'GET';

  return useSWR(method + '-' + url, async () => {
    return axios({
      url,
      method,
      headers: await getAuthHeaders()
    })
      .then(response => {
        if (response.data.success) {
          return response.data.success;
        } else {
          throw new Error(response.data.error);
        }
      })
      .catch(() => {
        throw new Error(translate('error.network', 'en'));
      });
  });
}

export const saveBranding = branding =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/teams/branding`,
      method: 'POST',
      data: { branding }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(e => {
        reject();
      });
  });

export const changeCurrentTeam = team =>
  new Promise((resolve, reject) => {
    api({
      url: `${API_HOST}/custom/profiles/change-current-team`,
      method: 'POST',
      data: { team }
    })
      .then(response => {
        if (response.data.success) resolve(response.data.success);
        else reject();
      })
      .catch(e => {
        reject();
      });
  });
